@import url('https://fonts.googleapis.com/css2?family=Poppins:ital,wght@0,500;0,600;1,400&display=swap');
 body {
  margin: 0;
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Lato', sans-serif; */

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: 'Poppins', sans-serif;
  /* font-family: 'Lato', sans-serif; */
} 

.backg-footer{
  background-color: #323232;
}
