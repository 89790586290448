figcaption{
    position: absolute;
    bottom: 3rem;
    margin: 0 2rem;
    color: #fff;
    font-weight: 700;
    
}

.map{
    fill: #dcd5d2;
    stroke: #000;
    stroke-width: 0.5;
    transition: fill 0.3s;
    /* stroke-linecap: round;
    stroke-linejoin: round; */
  }
  
  .maincolor{
    color:#fe5c00;
    background-color: #fe5c00;
   
  }
  
  .map-active{
    fill: #fe5c00;
   
  }


.image{
    max-width: 200px;
}

.imgHW{
    width: 150px; 
    height: 150px;
}

.imgsize{
    width: 100%;
    height: 502.2px;
    background: url("https://firebasestorage.googleapis.com/v0/b/oka-ventures.appspot.com/o/site-pics%2Fmeeting.jpeg?alt=media&token=95317a28-aa38-4a9f-b210-560a941a17f1");
    background-size: cover;
}


.square-holder {
    padding:30px;
    /* border: 1px solid #cecece; */
    align-items: center;
    display:flex;
    align-items: center;
    justify-content: center;
    margin-bottom:20px;
    /* background-color:#f1f1f1; */
    min-height:200px
  }
  
  .square-holder img {
    max-width:100%;
    /* filter: grayscale(100%); */
    transition: all 0.3s;
  }
  
  .square-holder:hover img{
    /* filter:none; */
    filter: grayscale(100%);
  }


  .contain {
    min-height: 100vh;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 100px 0;
    /* background-color: #111; */
  }

  .timeline {
    width: 80%;
    height: auto;
    max-width: 800px;
    margin: 0 auto;
    position: relative;
  }

  .timeline ul {
    list-style: none;
  }

  .timeline ul li {
    padding: 20px;
    background-color: #1e1f22;
    color: white;
    border-radius: 10px;
    margin-bottom: 20px;
  }

  .date {
    color: black;

  }

  .timeline ul li:last-child {
    margin-bottom: 0;
  }

  .timeline-content h1 {
    font-weight: 500;
    font-size: 25px;
    line-height: 30px;
    margin-bottom: 10px;
  }

  .timeline-content p {
    font-size: 16px;
    line-height: 30px;
    font-weight: 300;
  }

  .timeline-content .date {
    font-size: 12px;
    font-weight: 300;
    margin-bottom: 10px;
    letter-spacing: 2px;
  }

  @media only screen and (min-width: 768px) {
   
    .timeline:before {
      content: "";
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
      width: 2px;
      height: 100%;
      background-color: gray;
    }

    .timeline ul li {
      width: 50%;
      position: relative;
      margin-bottom: 50px;
    }

    .timeline ul li:nth-child(odd) {
      float: left;
      clear: right;
      transform: translateX(-30px);
      border-radius: 20px 0px 20px 20px;
    }

    .timeline ul li:nth-child(even) {
      float: right;
      clear: left;
      transform: translateX(30px);
      border-radius: 0px 20px 20px 20px;
    }

    .timeline ul li::before {
      content: "";
      position: absolute;
      height: 20px;
      width: 20px;
      border-radius: 50%;
      background-color: gray;
      top: 0px;
    }

    .timeline ul li:nth-child(odd)::before {
      transform: translate(50%, -50%);
      right: -30px;
    }

    .timeline ul li:nth-child(even)::before {
      transform: translate(-50%, -50%);
      left: -30px;
    }

    .timeline-content .date {
      position: absolute;
      top: -30px;
    }

    .timeline ul li:hover::before {
      background-color: #F4823C;
    }

  }
.testmonial-color{
     color: #F4823C;
     font-weight: 800;
}
 
