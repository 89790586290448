html {
    scroll-behavior: smooth;
    
}

.main-header {
    border-bottom: 1px solid #dddddd;
    padding: 1rem 0;
    transition: box-shadow ease .3s, background ease .3s, padding ease .5s;
}

.on-scroll-header {
    padding: .5rem 0;
    /* box-shadow: 0 2px 39px 3px rgba(0, 0, 0, 0.1); */
    box-shadow: rgba(0, 0, 0, 0.1) 0px 1px 3px 0px, rgba(0, 0, 0, 0.06) 0px 1px 2px 0px;

}

.on-scroll-header a {
    color: #ffffff;
}

.hamburger-menu {
    outline: 0 !important;
    box-sizing: none !important;
    border: 0 !important;
    background: none;
}

.top-content {
    padding-top: 60px;
}

.carousel-caption {
    width: 50%;
    z-index: 10;
    color: #fff;
    text-align: left;
    top: 50%;
    left: 75%;
    bottom: auto;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);
}
/* Cards */
.cards {
    display: flex;
    justify-content: center;
    align-content: space-between;
    flex-wrap:wrap;
  }

/* .card:hover {
    transform: scale(1.03);
    box-shadow: 0 13px 40px -5px hsla(240, 30.1%, 28%, 0.12), 0 8px 32px -8px hsla(0, 0%, 0%, 0.14), 0 -6px 32px -6px hsla(0, 0%, 0%, 0.02);
  } */
  

  .backg-footer{
    background-color: #323232;
  }

@media (max-width: 768px) {
    .navbar-collapse {
        opacity: 0;
        transition: height .5s ease-in-out opacity .7s ease-in-out;
    }

    .navbar-collapse.collapse.show {
        height: 100vh !important;
        opacity: 1;
    }

    .carousel-caption{
        font-size: medium;
    }
}